import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 899.000000 899.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,899.000000) scale(0.100000,-0.100000)">
					<path d="M3610 8118 c-534 -22 -1076 -188 -1540 -470 -159 -96 -221 -140 -384
-268 -68 -54 -224 -196 -294 -269 -515 -533 -847 -1236 -921 -1951 -6 -58 -13
-124 -16 -146 -4 -39 -2 -43 34 -67 l37 -25 115 39 c63 22 123 43 134 48 11 5
58 22 105 37 127 41 382 133 394 143 6 5 31 16 56 24 25 9 90 31 145 51 55 19
183 64 285 99 360 126 473 167 484 176 22 18 165 61 203 61 27 0 55 -10 90
-32 62 -39 176 -119 183 -128 3 -3 82 -57 175 -119 94 -62 175 -119 181 -127
10 -13 139 -102 349 -242 97 -66 101 -67 165 -67 53 0 99 11 250 62 102 33
239 78 305 98 66 21 129 41 140 45 11 5 79 27 152 50 72 22 133 44 135 48 5
12 180 65 215 66 48 0 76 -30 69 -74 -3 -22 0 -42 9 -54 8 -11 15 -27 15 -37
0 -25 35 -64 74 -83 32 -15 39 -15 71 -2 38 16 22 -14 256 473 27 56 49 107
49 113 0 6 11 33 25 60 l25 49 -22 14 c-13 8 -68 39 -123 70 -55 30 -169 94
-254 141 -84 47 -159 83 -165 79 -6 -3 -8 -2 -3 2 4 5 -14 20 -40 35 l-48 26
-57 -114 -58 -114 19 -24 c11 -14 22 -38 26 -54 3 -16 13 -34 21 -41 19 -16
18 -35 -3 -44 -10 -4 -126 -45 -258 -90 -132 -46 -346 -120 -475 -165 -129
-45 -250 -87 -268 -93 -28 -9 -37 -7 -62 12 -17 12 -36 19 -42 15 -6 -4 -8 -3
-5 4 6 9 -26 37 -203 175 -19 15 -64 50 -100 77 -36 27 -72 55 -80 62 -8 7
-62 50 -120 95 -58 45 -131 103 -162 128 -32 26 -62 43 -68 40 -6 -4 -9 -1 -8
6 5 19 -213 181 -237 176 -11 -3 -96 -32 -190 -65 -93 -33 -280 -100 -415
-147 -135 -48 -315 -112 -400 -142 -85 -31 -185 -66 -221 -78 -36 -13 -129
-46 -205 -73 -173 -62 -204 -69 -204 -48 0 43 89 288 163 446 74 160 220 406
294 495 5 6 31 42 58 80 57 80 347 382 446 465 217 181 519 364 759 460 342
137 662 205 1015 217 422 13 804 -55 1200 -214 111 -45 351 -166 379 -192 12
-10 62 -44 111 -76 162 -103 277 -197 445 -365 191 -190 186 -184 312 -356
101 -137 158 -222 193 -289 96 -179 155 -298 168 -335 8 -25 25 -67 37 -95 28
-64 83 -240 115 -364 43 -170 71 -366 76 -525 2 -57 5 -70 21 -73 10 -3 15 -9
13 -14 -9 -13 35 -31 60 -25 13 3 30 13 38 21 8 8 53 32 99 53 82 38 138 88
123 111 -3 5 4 13 16 16 20 7 21 11 14 88 -20 244 -129 738 -158 720 -6 -4 -7
-1 -2 6 7 13 -64 223 -74 216 -3 -2 -7 7 -7 19 -4 66 -177 389 -337 630 -129
195 -392 485 -582 642 -33 27 -62 51 -65 55 -21 23 -165 129 -260 191 -211
137 -384 228 -590 310 -473 188 -897 258 -1420 236z"/>
<path d="M2225 4951 c-203 -76 -566 -212 -805 -301 -239 -90 -470 -176 -513
-191 -43 -16 -84 -32 -90 -36 -7 -4 -17 -8 -24 -9 -11 -2 -208 -73 -260 -94
-17 -7 -23 -18 -23 -42 0 -62 33 -313 43 -326 12 -15 54 -8 77 13 8 7 114 50
235 94 439 161 599 222 619 234 12 8 111 46 221 86 110 39 214 79 230 87 17 9
113 47 214 85 102 38 187 73 190 77 3 5 38 19 77 33 83 29 118 25 161 -22 15
-16 58 -52 94 -79 37 -27 84 -64 105 -83 58 -52 224 -185 433 -345 102 -79
193 -151 201 -160 24 -28 133 -109 157 -116 23 -7 396 99 401 115 2 5 9 9 15
9 10 0 185 51 722 211 110 33 225 67 255 76 30 9 60 20 65 24 6 4 80 28 165
53 85 26 209 63 275 82 66 20 125 40 130 44 6 5 89 32 185 60 96 29 190 59
208 67 l32 15 0 77 c0 42 -3 133 -7 202 -6 122 -7 126 -27 122 -12 -3 -91 -27
-176 -53 -85 -27 -186 -58 -225 -69 -38 -10 -218 -64 -400 -119 -1686 -511
-1524 -465 -1553 -446 -15 10 -98 71 -187 136 -224 166 -244 182 -258 193 -7
6 -52 40 -102 77 -49 37 -178 132 -285 212 -107 80 -197 145 -200 145 -3 0
-171 -62 -375 -138z"/>
<path d="M8012 4993 c-6 -16 -31 -76 -55 -134 l-45 -106 43 -36 c47 -40 61
-77 36 -102 -16 -16 -152 -61 -766 -254 -192 -60 -982 -308 -1755 -551 -773
-243 -1499 -471 -1614 -507 -189 -59 -211 -64 -232 -52 -12 8 -246 188 -518
402 -272 213 -500 387 -506 387 -5 0 -292 -104 -637 -231 -346 -126 -752 -276
-903 -331 -151 -56 -279 -105 -284 -109 -10 -9 12 -63 86 -217 28 -56 54 -102
59 -102 5 0 8 -3 7 -7 -3 -11 35 -73 44 -73 4 0 8 -6 8 -14 0 -7 13 -30 30
-51 16 -20 32 -46 36 -58 3 -12 21 -40 39 -62 18 -22 56 -72 86 -112 30 -39
70 -88 89 -110 19 -21 51 -58 70 -83 140 -182 572 -534 800 -651 47 -23 87
-46 90 -50 12 -18 245 -126 383 -179 186 -71 508 -160 578 -160 10 0 19 -4 19
-8 0 -9 98 -26 250 -43 156 -18 578 -14 725 6 66 9 139 19 162 22 24 3 40 9
37 14 -3 5 7 9 21 9 32 0 246 51 363 86 223 67 493 187 672 297 230 141 385
250 445 312 22 22 67 63 100 90 64 54 245 235 245 246 0 3 21 28 48 55 50 52
121 136 136 163 6 9 22 30 38 46 15 17 28 33 28 37 0 4 11 24 25 45 14 21 30
45 35 53 5 8 33 51 62 95 132 204 286 529 368 778 30 94 83 144 188 179 42 14
141 49 220 76 78 27 142 47 142 43 0 -3 4 -1 8 4 4 6 63 29 132 52 136 46 365
126 393 138 26 11 61 -1 92 -32 25 -26 27 -32 21 -85 l-6 -58 63 -40 c69 -44
163 -78 177 -64 4 5 85 160 179 346 l172 336 -53 30 c-120 68 -651 352 -658
352 -5 0 -13 -12 -18 -27z m-3331 -1963 c64 -18 109 -88 109 -171 l0 -39 -55
0 c-53 0 -55 1 -55 28 0 29 -24 72 -40 72 -5 0 -17 -11 -25 -24 -26 -39 -9
-72 76 -154 50 -47 83 -88 91 -111 15 -45 15 -156 -1 -202 -34 -105 -170 -140
-246 -64 -34 35 -55 96 -55 164 l0 41 54 0 c59 0 66 -7 66 -65 0 -59 55 -76
73 -22 15 47 -1 78 -83 157 -85 83 -100 114 -100 206 0 90 33 152 95 180 37
16 50 17 96 4z m-2553 -307 c-3 -278 -4 -300 -23 -333 -43 -74 -156 -91 -215
-31 -29 29 -50 101 -50 171 l0 40 55 0 c60 0 61 -2 67 -80 2 -23 9 -36 19 -38
24 -4 30 61 29 325 0 126 -1 231 0 236 0 4 27 7 61 7 l61 0 -4 -297z m278 157
c12 -74 26 -169 30 -210 l8 -75 8 55 c5 30 18 116 29 190 30 201 17 180 115
180 l84 0 0 -340 0 -340 -59 0 -59 0 4 233 c2 127 2 228 0 222 -3 -5 -19 -102
-36 -215 -17 -113 -33 -213 -36 -222 -4 -14 -16 -18 -50 -18 -24 0 -44 1 -45
3 0 1 -18 108 -39 237 l-40 235 5 -237 5 -238 -60 0 -60 0 0 340 0 341 87 -3
88 -3 21 -135z m635 -200 l55 -340 -62 0 -62 0 -7 65 -7 65 -49 0 c-47 0 -49
-1 -49 -27 0 -16 -3 -45 -6 -65 l-7 -38 -58 0 c-47 0 -59 3 -59 16 0 16 96
601 105 642 5 20 11 22 78 22 l72 0 56 -340z m378 306 c65 -62 70 -218 10
-282 -21 -22 -21 -25 -6 -42 26 -29 37 -69 46 -182 5 -58 12 -113 16 -122 6
-16 0 -18 -63 -18 l-69 0 -7 38 c-3 20 -6 72 -6 115 0 94 -10 117 -50 117
l-30 0 0 -135 0 -135 -60 0 -60 0 0 341 0 341 124 -3 c122 -4 125 -5 155 -33z
m421 -30 l0 -65 -52 -3 -53 -3 -3 -272 -2 -273 -60 0 -60 0 0 275 0 275 -50 0
-50 0 0 65 0 65 165 0 165 0 0 -64z m170 -276 l0 -340 -65 0 -65 0 0 340 0
340 65 0 65 0 0 -340z m253 158 l46 -183 -4 183 -4 182 54 0 55 0 0 -340 0
-340 -63 0 -62 0 -19 83 c-10 45 -30 134 -46 197 l-27 115 5 -197 5 -198 -56
0 -57 0 0 340 0 340 64 0 63 0 46 -182z m719 -88 c4 -237 7 -273 21 -288 25
-24 50 -5 59 44 4 21 7 146 7 277 l1 237 61 0 61 0 -4 -287 c-3 -276 -4 -290
-26 -330 -28 -54 -71 -77 -142 -77 -69 -1 -110 26 -136 86 -16 38 -19 79 -22
326 l-4 282 60 0 59 0 5 -270z m522 256 c50 -21 78 -67 83 -139 4 -52 1 -70
-21 -112 -22 -42 -23 -51 -12 -65 32 -36 46 -81 46 -147 0 -77 -21 -133 -64
-173 -26 -23 -36 -25 -147 -29 l-119 -3 0 341 0 341 100 0 c63 0 113 -5 134
-14z m-2351 -787 c-47 -236 -46 -234 -76 -237 -24 -3 -28 0 -24 15 3 10 15 63
27 118 28 136 28 135 55 135 22 0 24 -3 18 -31z m105 5 c2 -14 -3 -23 -15 -26
-38 -10 -57 14 -31 40 18 18 43 10 46 -14z m447 1 c0 -14 -8 -21 -27 -23 -30
-4 -38 14 -16 36 18 18 43 10 43 -13z m579 -2 c-43 -212 -50 -238 -72 -241
-12 -2 -22 2 -22 8 0 7 -6 8 -19 0 -26 -14 -74 -13 -89 2 -17 17 -15 84 4 124
21 44 47 58 95 51 38 -5 39 -4 45 31 9 49 11 52 39 52 21 0 24 -4 19 -27z
m326 20 c-1 -5 -13 -64 -28 -133 -24 -112 -30 -125 -50 -128 -12 -2 -22 2 -22
8 0 7 -6 8 -19 0 -32 -17 -79 -12 -91 9 -23 44 -6 115 39 155 15 14 30 17 60
13 l39 -5 11 44 c10 37 15 44 36 44 14 0 25 -3 25 -7z m-798 -51 c-2 -17 2
-22 17 -22 14 0 21 -6 21 -20 0 -15 -7 -20 -24 -20 -26 0 -30 -8 -42 -72 -6
-34 -4 -38 16 -38 15 0 20 -5 18 -17 -2 -14 -13 -18 -43 -18 l-40 0 3 73 c1
43 -2 72 -8 72 -19 0 -10 32 10 37 11 3 20 13 20 23 0 20 16 30 40 27 9 -2 14
-11 12 -25z m1122 0 c0 -17 5 -22 23 -22 34 0 27 -34 -8 -38 -23 -3 -28 -10
-36 -52 -11 -54 -9 -60 19 -60 20 0 21 -1 12 -24 -8 -21 -67 -21 -84 -1 -12
14 -10 44 7 113 4 16 1 22 -11 22 -18 0 -19 2 -10 24 3 9 12 16 20 16 8 0 14
6 14 13 0 19 22 38 40 34 8 -1 15 -13 14 -25z m-2248 -54 c-18 -90 -19 -118
-3 -118 28 0 56 41 67 96 10 47 14 54 36 54 28 0 29 -5 6 -110 -15 -64 -20
-75 -39 -78 -13 -2 -23 2 -23 8 0 7 -6 8 -19 0 -11 -5 -33 -10 -50 -10 -46 0
-54 25 -36 110 8 39 15 73 15 76 0 2 12 4 26 4 25 0 26 -2 20 -32z m224 22 c0
-7 6 -7 19 0 11 5 33 10 50 10 48 0 56 -27 36 -116 -15 -68 -18 -74 -42 -74
-22 0 -25 3 -19 28 17 78 19 122 4 122 -26 0 -57 -44 -68 -98 -10 -45 -15 -52
-36 -52 -13 0 -24 4 -24 9 0 13 29 152 35 169 5 15 45 16 45 2z m327 -32 c-3
-24 -11 -67 -17 -95 -10 -46 -15 -53 -36 -53 -28 0 -29 6 -9 97 21 93 21 93
46 93 20 0 21 -4 16 -42z m103 26 c0 -14 2 -14 22 0 30 21 75 20 87 -1 9 -17
10 -17 28 0 24 21 76 22 93 1 11 -12 11 -27 0 -82 -17 -90 -18 -92 -47 -92
-21 0 -24 4 -19 23 3 12 9 46 12 75 6 43 4 52 -9 52 -17 0 -57 -45 -57 -64 0
-6 -5 -28 -11 -48 -8 -31 -14 -38 -36 -38 -22 0 -24 3 -19 23 4 12 9 46 13 75
5 42 3 52 -9 52 -25 0 -50 -37 -63 -92 -10 -47 -15 -53 -39 -56 -23 -3 -27 0
-23 15 3 10 13 53 22 96 14 64 20 77 36 77 10 0 19 -7 19 -16z m350 -1 c-1
-10 -9 -52 -18 -93 -16 -68 -20 -75 -44 -78 -25 -3 -26 -2 -18 35 5 21 13 64
20 96 10 51 14 57 36 57 16 0 24 -6 24 -17z m340 -3 c39 -39 -11 -88 -92 -89
-21 -1 -28 -6 -28 -20 0 -25 22 -32 72 -25 39 7 40 6 29 -15 -10 -17 -21 -21
-62 -21 -71 0 -89 12 -89 61 0 47 21 91 52 113 32 23 94 20 118 -4z m728 8 c9
-9 12 -9 12 0 0 12 32 17 42 6 3 -3 2 -20 -2 -37 -5 -18 -14 -58 -20 -89 -10
-52 -14 -58 -36 -58 -14 0 -22 4 -19 10 3 6 2 10 -4 10 -5 0 -13 -4 -16 -10
-3 -5 -22 -10 -40 -10 -71 0 -85 95 -25 160 30 32 85 41 108 18z m363 2 c13
-7 19 -7 19 0 0 5 9 10 20 10 24 0 24 -3 4 -107 -15 -76 -19 -83 -40 -83 -13
0 -24 5 -24 10 0 7 -7 7 -20 0 -36 -19 -83 -8 -94 21 -22 64 29 159 86 159 16
0 38 -5 49 -10z m-2796 -130 c12 -20 -6 -50 -30 -50 -30 0 -35 9 -24 38 9 23
43 30 54 12z m110 -20 c0 -20 -6 -26 -27 -28 -22 -3 -28 1 -28 15 0 11 6 25
12 31 20 20 43 10 43 -18z m120 20 c12 -20 -6 -50 -30 -50 -30 0 -39 14 -25
40 11 21 44 27 55 10z"/>
<path d="M2907 2830 c-6 -27 -27 -196 -27 -223 0 -12 9 -17 30 -17 28 0 30 2
29 38 -2 77 -25 228 -32 202z"/>
<path d="M3260 2820 l0 -80 29 0 c39 0 53 21 54 79 1 58 -15 81 -54 81 l-29 0
0 -80z"/>
<path d="M5396 2894 c-3 -9 -6 -42 -6 -75 0 -56 1 -59 24 -59 31 0 56 34 56
77 0 52 -59 97 -74 57z"/>
<path d="M5394 2627 c-3 -8 -4 -47 -2 -88 3 -85 19 -99 63 -55 46 46 28 133
-33 151 -15 4 -24 2 -28 -8z"/>
<path d="M4135 2099 c-19 -37 -16 -73 7 -77 34 -7 79 73 55 97 -20 20 -46 11
-62 -20z"/>
<path d="M4455 2100 c-17 -33 -20 -66 -5 -75 34 -21 96 59 69 91 -19 23 -47
16 -64 -16z"/>
<path d="M3935 2120 c-23 -26 -15 -34 25 -25 22 5 30 12 28 23 -5 25 -32 26
-53 2z"/>
<path d="M4686 2108 c-9 -12 -16 -37 -16 -55 0 -26 4 -33 19 -33 27 0 63 48
59 79 -4 35 -40 40 -62 9z"/>
<path d="M5058 2113 c-33 -38 -31 -93 3 -93 23 0 60 56 57 85 -4 26 -40 31
-60 8z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
